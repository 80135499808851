import React from 'react';
import _ from 'lodash';
import PatientSnapshot from '../PatientSnapshot';

import classes from './Navigation.module.scss';



const Navigation = ({store, resourcesLength}) => {
  const measurements = _.get(store, 'measurements', []);
  const resources = _.get(store, 'records', []);

  return (
    <nav className={classes.navigation}>
      <div className={classes['navigation__left-panel']}>
        <PatientSnapshot />
      </div>

      <div className={classes['navigation__right-panel']}>
       {/* <div>{`Fetched ${resources.length || measurements.length ? resources.length + measurements.length : 0} resources`}</div> */}
      </div>
    </nav>
  );
};

export default Navigation;
