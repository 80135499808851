import React, { useState, useEffect, useReducer } from "react";
import logo from '../logos/logo.png';
import FHIR from 'fhirclient';
import _ from 'lodash';
import { getPatientRecord } from '../utils/fhirExtract';
import { FHIRClientProvider } from './FHIRClient';
import { StoreProvider } from './StoreProvider';
import PatientRecord from './PatientRecord';
import Header from './Header';
import Navigation from './Navigation'
import { api, getPatients, setBearerToken } from '../api';
import { getNameArray } from '../utils/helper';
import LoginModal from './Login/LoginModal';
import axios from 'axios';
import jwt_decode from "jwt-decode";
/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateClient':
      return {...state, client: action.client};
    case 'updatePatient':
      return {...state, patient: action.patient};
    case 'updatePatients':
      return {...state, patients: action.patients};
    case 'updateUser': 
      return {...state, user: action.user};
    case 'updateRecords':
      return {...state, records: action.records};
    case 'updateEnroll':
      return {...state, enroll: action.enroll};
    case 'updateObservations':
      return {...state, observations: action.observations};
    case 'updateEncounters': 
      return {...state, encounters: action.encounters}
    case 'updateConditions':
      return {...state, conditions: action.conditions}
    case 'updateMedications':
      return {...state, medications: action.medications}
    case 'updateBgMeasurements':
      return {...state, bgMeasurements: action.bgMeasurements}
    case 'updateBpMeasurements':
      return {...state, bpMeasurements: action.bpMeasurements}
    case 'updateEnrolledPatient':
      return {...state, enrolledPatient: action.enrolledPatient}
    case 'updateEhrPractitioner':
      return {...state, ehrPractitioner: action.ehrPractitioner}
    case 'updateUcPractitioner': 
      return {...state, ucPractitioner: action.ucPractitioner}
    case 'updateLoggedIn':
      return {...state, loggedIn: action.loggedIn}
    case 'updateIhPractitioner': 
      return {...state, ihPractitioner: action.ucPractitioner}
    case 'updateOrganizationId':
      return {...state, organizationId: action.organizationId}
    case 'updateEnrollStatus':
      return {...state, enrollStatus: action.enrollStatus}
    default: 
      return state
  }
}

export default function Home (props) {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [fhir, setFhir] = useState(null);
  const [error, setError] = useState('');
  const initState = {
    loggedIn: false
  };
  const [state, dispatch] = useReducer(reducer, initState);
  useEffect(() => {
    props.location.standaloneLaunch ? iHealthLaunch() : FhirLaunch();
  }, [])

  const loadPatientInfoWithClient = (client) => {
    console.log(client)
    getPatientRecord(client).then((records) => {
      console.log(records)
      client.patient.read().then((patient) => dispatch({type: "updatePatient", patient}))
      dispatch({type: "updateRecords", records})
      dispatch({type: 'updateObservations', observations: records.filter((resource) => resource.resourceType === 'Observation')})
    })
  }

  const matchPractitioner = async (client) => {
    const practitionerId = _.get(client,'state.tokenResponse.practitioner');
    const practitioner = practitionerId ? await client.request(`/Practitioner/${practitionerId}`) : "";
    const epicIdToken = _.get(client, 'state.tokenResponse.id_token');
    const epicAccountId = _.get(client, 'state.tokenResponse.practitioner');
    if (practitioner) {
        dispatch({type: 'updateEhrPractitioner', ehrPractitioner: practitioner});
    }
    const body = {
      applicationName: "ucfhir",
      method: "token",
      parameters: {
        epicToken: epicIdToken
      }
    }
    axios.post(process.env.REACT_APP_SOTER_URL + '/app/account/login', body).then((authRes) => {
      if (authRes.status === 200) {
        const bearerToken = _.get(authRes, 'data');
        const decoded = jwt_decode(bearerToken);
        const ucId = _.get(decoded, 'sub');
        setBearerToken(bearerToken);
        api.get(`Practitioner/${ucId}`).then((ucPractitioner) => {
          dispatch({type: 'updateUcPractitioner', ucPractitioner: _.get(ucPractitioner, 'data')});
          dispatch({type: 'updateIhPractitioner', ucPractitioner});
          dispatch({type: 'updateLoggedIn', loggedIn: true});
        }).catch((e) => {
          setError('We could not find your account, please contact us to help resolve this issue.');
          setLogin(true);
        })
      }
    }).catch((e) => setLogin(true))
  }

  const FhirLaunch = () => {
    FHIR.oauth2.ready().then((client) => {
      dispatch({type: "updateClient", client});
      matchPractitioner(client);
      setFhir(client);
      loadPatientInfoWithClient(client);
      setLoading(false);
    })
  }

  const iHealthLaunch = () => {
    getPatients().then((bundle) => {
      dispatch({type: "updatePatients", patients: bundle.data.entry});
      setLoading(false)
    })
  }

  const renderPage = () => {
      return (
        state.loggedIn ? <div>
          <div className="header-container">
            <Header logo={logo} store={state}/>
            <Navigation store={state} resourcesLength={state.records && state.records.length}/>
          </div>
          <div>
            <PatientRecord client={fhir} resources={state.records} store={state} loading={loading} dispatch={dispatch} />
          </div>
        </div> : null
      )
  }

  return (
    <FHIRClientProvider fhir={fhir}>
      <StoreProvider store={state} dispatch={dispatch}>
        {renderPage()}
        <LoginModal visible={login} setVisible={setLogin} error={error} setError={setError}/>
    </StoreProvider>
  </FHIRClientProvider>
  )
}
