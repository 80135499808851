import React from 'react';
import { Modal, Button } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Tabs, Tag, Input } from 'antd';
import { getBgStage, getBpStage } from './helper';

const { TabPane } = Tabs;
const { TextArea } = Input;

const MeasurementModal = ({modalData, setModalData, visible, setVisible}) => {
  const renderMeasurements = () => {
    return <Tabs defaultActiveKey='0'>
      {modalData.map((m, i) => {
        const code =  _.get(m, 'resource.code.text');
        const extension = _.get(m, 'resource.extension');
        const tzObj = _.find(extension, (e) => e.url === 'timezone');
        const timeZone = _.get(tzObj, 'valueCode', "");
        const utcTime = _.get(m, 'resource.effectiveDateTime');
        const patientTime = moment(utcTime).tz(timeZone);
        const dateTime = patientTime.format('YYYY-MM-DD ddd hh:mm a');
        const val = _.get(m, 'resource.valueQuantity.value');
        const hadInsulin = _.get(_.find(extension, e => e.url === 'hadInsulin'), 'valueCode', "N/A");
        const hadActivity = _.get(_.find(extension, e => e.url === 'hadActivity'), 'valueCode', "N/A");
        const hadMedicine = _.get(_.find(extension, e => e.url === 'hadMedicine'), 'valueCode', "N/A");
        const notes = _.get(_.find(extension, e => e.url === 'user_notes'), 'valueCode', "");
        const moodTags = _.get(_.find(extension, e => e.url === 'moodTags'), 'valueCode', "N/A").split(',');
        
        if (code === 'Glucose' || code === 'Glucose, Fasting') {
          const bgStage = getBgStage(m);  
          return <TabPane key={i} tab={<div className={`measurement-tab ${bgStage}`}>{Math.round(val * 18)} mg/dL</div>}>
            <div style={{display: 'flex', flexDirection: 'column'}} className='measurement-tab-content'>
              <div><b>{dateTime}</b></div> 
              <div><b>Moods and Symptoms:</b></div>
              <div>{moodTags.map((mood, i) => <Tag key={i}>{mood}</Tag>)}</div>
              <div><b>Activity:</b> {hadActivity}</div>
              <div><b>Medicine:</b> {hadMedicine}</div>
              <div><b>Insulin:</b> {hadInsulin}</div>
              <div><b>Patient Notes (Measurement):</b></div>
              <TextArea disabled value={notes}></TextArea>
            </div>
          </TabPane>
        } else if (code === 'Blood pressure panel with all children optional') {
          const bpm = _.get(m, 'resource.component[0].valueQuantity.value');
          const sysVal = _.get(m, 'resource.component[1].valueQuantity.value');
          const diaVal = _.get(m, 'resource.component[2].valueQuantity.value');
          const hadMedicine = _.get(_.find(extension, e => e.url === 'hadMedicine'), 'valueCode', "N/A");
          const notes = _.get(_.find(extension, e => e.url === 'user_notes'), 'valueCode', "");
          const bpStage = getBpStage(m);
          return <TabPane key={i} tab={<div className={`measurement-tab ${bpStage}`}>{sysVal}/{diaVal} {bpm}bpm</div>}>
            <div style={{display: 'flex', flexDirection: 'column'}} className='measurement-tab-content'>
              <div><b>{dateTime}</b></div>
              <div><b>Medicine:</b> {hadMedicine}</div>
              <div><b>Patient Notes (Measurement):</b></div>
              <TextArea disabled value={notes}></TextArea>
            </div>
          </TabPane>
        } else {
          return ""
        }
      })}
    </Tabs>
  }

  return modalData ? <Modal
        visible={visible}
        closable={false}
        onOk={() => {setVisible(false)}}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}
        animated={false}
      >
         {renderMeasurements()}
      </Modal> : null;
}

export default MeasurementModal;