import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './TabStyle.scss';

export const Tab = ({ children }) => {
  const [active, setActive] = useState();
  const tabs = React.Children.map(children, element => element.props.title);
  
  useEffect(() => {
    if (active === undefined) {
      setActive(tabs[0]);
    }
  }, [tabs]);

  const tabContents = React.Children.map(children, (element, index) => (
      <div key={index} className={'tab-content ' + (active === element.props.title ? 'active' : '')}>
        {element}
      </div>
    )
  );
  
  return (
    <div>
      <div className="tab">
        {tabs.map(t => <button key={t} className={'tab-links ' + (active === t ? 'active' : '')} onClick={() => { setActive(t) }}>{t}</button>)}
      </div>
      {tabContents}
    </div>
  );
}

const TabPane = ({ title, children }) => {
  return children;
}

Tab.TabPane = TabPane;

export default Tab;
