import React from 'react';
import classes from './Header.module.scss';
import logo from '../../logos/smartonfhir-logo.png';

const Header = ({store}) => {
  return (
    <header className={classes.header}>
      <img className={classes['header-logo']} src={logo} alt='logo'/>
    </header>
  );
};

export default Header;
