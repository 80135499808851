import _ from 'lodash';
import moment from 'moment-timezone';
import { 
  BG_CRIT_LO,
  BG_CRIT_HI,
  BP_SYS_LO,
  BP_SYS_NORMAL,
  BP_SYS_ELEVATED,
  BP_SYS_STAGE_ONE,
  BP_SYS_STAGE_TWO,
  BP_SYS_CRIT_HI,
  BP_DIA_NORMAL,
  BP_DIA_STAGE_ONE,
  BP_DIA_STAGE_TWO,
  BP_DIA_CRIT_HI,
  BG_LO,
  BG_NORMAL,
  BG_HI
} from '../../utils/constants';

export const parseBgSummaryData = (measurements) => {
  const fasting = [];
  const before = [];
  const after = [];
  const bed = [];
  const overnight = [];
  const critHi = [];
  const critLo = [];

  for (const m of measurements) {
    const code =  _.get(m, 'resource.code.text');
    const extension = _.get(m, 'resource.extension');
    const mealObj =_.find(extension, (e) => e.url === 'mealType');
    const meal = _.get(mealObj, 'valueCode');
    const beforeObj = _.find(extension, (e) => e.url === 'beforeMeal');
    const beforeMeal = _.get(beforeObj, 'valueCode');
    const val = _.get(m, 'resource.valueQuantity.value');
    if (code === 'Glucose, Fasting') {
      fasting.push(m);
      }
    if (beforeMeal === 'false') {
      after.push(m);
    }
    if (beforeMeal === 'true') {
      before.push(m)
    }
    if (meal === 'OVERNIGHT') {
      overnight.push(m);
    }
    if (meal === 'BEDTIME') {
      bed.push(m);
    }
    if (between(val, BG_CRIT_LO)) {
      critLo.push(m);
    }
    if (val >= BG_CRIT_HI) {
      critHi.push(m)
    }
  }

  return [
    {type: 'Fasting', average: getBgAverage(fasting), count: fasting.length, range: getBgRange(fasting)},
    {type: 'Before Meal', average: getBgAverage(before), count: before.length, range: getBgRange(before)},
    {type: 'After Meal', average: getBgAverage(after), count: after.length, range: getBgRange(after)},
    {type: 'Bedtime', average: getBgAverage(bed), count: bed.length, range: getBgRange(bed)},
    {type: 'Overnight', average: getBgAverage(overnight), count: overnight.length, range: getBgRange(overnight)},
    {type: 'Bedtime+Overnight Hypos', average: getBgAverage(bed.concat(overnight)), count: bed.length + overnight.length, range: getBgRange(bed.concat(overnight))},
    {type: 'Critical Low', average: getBgAverage(critLo), count: critLo.length, range: getBgRange(critLo)},
    {type: 'Critical High', average: getBgAverage(critHi), count: critHi.length, range: getBgRange(critHi)}
  ]
}

export const parseBpTableData = (array) => {
  const parsed = {};

  for (const m of array) {
    const timeObj = _.find(m.resource.extension, (e) => e.url === 'timezone');
    const timeZone = _.get(timeObj, 'valueCode');
    const utcTime = _.get(m, 'resource.effectiveDateTime');
    const patientTime = moment(utcTime).tz(timeZone);
    const date = patientTime.format('YYYY-MM-DD');
    const hour = patientTime.hour();

    if (!parsed[date]) {
      parsed[date] = {};
      parsed[date].date = date;
      parsed[date].overnight = [];
      parsed[date].morning = [];
      parsed[date].afternoon = [];
      parsed[date].evening = [];
    }
    if (hour >= 0 && hour < 6) {
      parsed[date].overnight.push(m);
    }
    if (hour >= 6 && hour < 12) {
      parsed[date].morning.push(m);
    }
    if (hour >= 12 && hour < 16) {
      parsed[date].afternoon.push(m);
    }
    if (hour >= 16 && hour < 24) {
      parsed[date].evening.push(m);
    }
  }

  return parsed;
}

export const parseBgTableData = (array) => {
  const parsed = {};
  for (const m of array) {
    const extension = _.get(m, 'resource.extension');
    const mealObj =_.find(extension, (e) => e.url === 'mealType');
    const meal = _.get(mealObj, 'valueCode');
    const beforeObj = _.find(extension, (e) => e.url === 'beforeMeal');
    const beforeMeal = _.get(beforeObj, 'valueCode');
    const tzObj = _.find(extension, (e) => e.url === 'timezone');
    const timeZone = _.get(tzObj, 'valueCode', "");
    const utcTime = _.get(m, 'resource.effectiveDateTime');
    const patientTime = moment(utcTime).tz(timeZone);
    const date = patientTime.format('YYYY-MM-DD');

    if (!parsed[date]) {
      parsed[date] = {};
      parsed[date].date = date;
      parsed[date].breakfast = { before: [], after: []};
      parsed[date].lunch = { before: [], after: []};
      parsed[date].dinner = { before: [], after: []};
      parsed[date].snack = { before: [], after: []};
      parsed[date].overnight = [];
      parsed[date].bedtime = [];
    }
    if (meal === 'BREAKFAST') {
      if (beforeMeal === 'true') {
        parsed[date].breakfast.before.push(m)
      }
      if (beforeMeal === 'false') {
        parsed[date].breakfast.after.push(m)
      }
    }
    else if (meal === 'LUNCH') {
      if (beforeMeal === 'true') {
        parsed[date].lunch.before.push(m)
      }
      if (beforeMeal === 'false') {
        parsed[date].lunch.after.push(m)
      }
    }
    else if (meal === 'DINNER') {
      if (beforeMeal === 'true') {
        parsed[date].dinner.before.push(m)
      }
      if (beforeMeal === 'false') {
        parsed[date].dinner.after.push(m)
      }
    }
    else if (meal === 'SNACK') {
      if (beforeMeal === 'true') {
        parsed[date].snack.before.push(m)
      }
      if (beforeMeal === 'false') {
        parsed[date].snack.after.push(m)
      }
    } 
    else if (meal === 'OVERNIGHT') {
      parsed[date].overnight.push(m)
    } 
    else if (meal === 'BEDTIME') {
      parsed[date].bedtime.push(m)
    }
  }

  return parsed;
}

export const parseBpSummaryData = (measurements) => { 
  const overnight = [];
  const morning = [];
  const afternoon = [];
  const evening = [];
  const normal = [];
  const elevated = [];
  const stage1 = [];
  const stage2 = [];
  const critHi = [];
  // const arrhythmia = [];
  
  for (const m of measurements) {
    const sysVal = _.get(m, 'resource.component[1].valueQuantity.value');
    const diaVal = _.get(m, 'resource.component[2].valueQuantity.value');
    const extension = _.get(m, 'resource.extension');
    const timeObj = _.find(extension, (e) => e.url === 'timezone');
    const timeZone = _.get(timeObj, 'valueCode');
    const utcTime = _.get(m, 'resource.effectiveDateTime');
    const patientTime = moment(utcTime).tz(timeZone);
    const hour = patientTime.hour();

    if (between(sysVal, BP_SYS_CRIT_HI) || between(diaVal, BP_DIA_CRIT_HI)) {
      critHi.push(m)
    }
    else if (between(sysVal, BP_SYS_STAGE_TWO) || between(diaVal, BP_DIA_STAGE_TWO)) {
      stage2.push(m)
    }
    else if (between(sysVal, BP_SYS_STAGE_ONE) || between(diaVal, BP_DIA_STAGE_ONE)) {
      stage1.push(m)
    }
    else if (between(sysVal, BP_SYS_ELEVATED) && between(diaVal, BP_DIA_NORMAL)) {
      elevated.push(m)
    }
    else if (between(sysVal, BP_SYS_NORMAL) && between(diaVal, BP_DIA_NORMAL)) {
      normal.push(m)
    }
    if (hour >= 0 && hour < 6) {
      overnight.push(m);
    }
    if (hour >= 6 && hour < 12) {
      morning.push(m)
    }
    if (hour >= 12 && hour < 16) {
      afternoon.push(m)
    }
    if (hour >= 16 && hour < 24) {
      evening.push(m)
    }
  }

  return [
    {type: 'All', average: getBpAverage(measurements), count: measurements.length, range: getBpRange(measurements)},
    {type: 'Overnight', average: getBpAverage(overnight), count: overnight.length, range: getBpRange(overnight)},
    {type: 'Morning', average: getBpAverage(morning), count: morning.length, range: getBpRange(morning)},
    {type: 'Afternoon', average: getBpAverage(afternoon), count: afternoon.length, range: getBpRange(afternoon)},
    {type: 'Evening', average: getBpAverage(evening), count: evening.length, range: getBpRange(evening)},
    {type: 'Normal', average: getBpAverage(normal), count: normal.length, range: getBpRange(normal)},
    {type: 'Elevated', average: getBpAverage(elevated), count: elevated.length, range: getBpRange(elevated)},
    {type: 'Stage 1 Hypertension', average: getBpAverage(stage1), count: stage1.length, range: getBpRange(stage1)},
    {type: 'Stage 2 Hypertension', average: getBpAverage(stage2), count: stage2.length, range: getBpRange(stage2)},
    {type: 'Critical High', average: getBpAverage(critHi), count: critHi.length, range: getBpRange(critHi)},
    // {type: 'Arrhythmia', average: getBpAverage(arrhythmia), count: arrhythmia.length},
  ]
}

export const getBgAverage = (arr) => {
  if (!arr.length) {
    return "N/A";
  }
  let sum = 0;

  for (const a of arr) {
    let val = _.get(a, 'resource.valueQuantity.value', 0);
    sum += val;
  }

  return Math.round(sum / arr.length) * 18;
}

export const getBpAverage = (arr) => {
  if (!arr.length) {
    return "N/A";
  }
  let sysTotal = 0;
  let diaTotal = 0;

  for (const m of arr) {
    const sysVal = _.get(m, 'resource.component[1].valueQuantity.value');
    const diaVal = _.get(m, 'resource.component[2].valueQuantity.value');
    sysTotal += sysVal;
    diaTotal += diaVal;
  }
  
  return Math.round(sysTotal/arr.length) + '/' + Math.round(diaTotal/arr.length);
}

export const getBgRange = (arr) => {
  if (!arr.length) {
    return "N/A";
  }
  let low = Infinity;
  let high = -Infinity;

  for (const a of arr) {
    let val = _.get(a, 'resource.valueQuantity.value');
    low = Math.min(low, val);
    high = Math.max(high, val);
  }
  
  return Math.round(low) * 18 + '-' + Math.round(high) * 18;
}

export const getBpRange = (arr) => {
  if (!arr.length) {
    return "N/A";
  }
  let sysLow = Infinity;
  let sysHi = -Infinity;
  let diaLow = Infinity;
  let diaHi = -Infinity;
  
  for (const m of arr) {
    const sysVal = _.get(m, 'resource.component[1].valueQuantity.value');
    const diaVal = _.get(m, 'resource.component[2].valueQuantity.value');
    sysLow = Math.min(sysVal, sysLow);
    sysHi = Math.max(sysVal, sysHi);
    diaLow = Math.min(diaVal, diaLow);
    diaHi = Math.max(diaVal, diaHi);
  }
  
  return sysLow + '-' + sysHi + '/' + diaLow + '-' + diaHi;
}

export const getBgStage = (resource) => {
  if (!resource || resource === undefined) {
    return "";
  }
  let stage = '';
  const val = _.get(resource, 'valueQuantity.value') || _.get(resource, 'resource.valueQuantity.value');
  if (between(val, BG_NORMAL)){
    stage = 'bg-normal';
  }
  else if (between(val, BG_LO) || between(val, BG_HI)) {
    stage = 'bg-abnormal';
  }
  else if (between(val, BG_CRIT_LO) || val >= BG_CRIT_HI){
    stage = 'bg-crit';
  }
  return stage;
}

export const getBpStage = (resource) => {
  if (!resource || resource === undefined) {
    return "";
  }
  let stage = '';
  const sysVal = _.get(resource, 'resource.component[1].valueQuantity.value');
  const diaVal = _.get(resource, 'resource.component[2].valueQuantity.value');

  if (between(sysVal, BP_SYS_CRIT_HI) || between(diaVal, BP_DIA_CRIT_HI)) {
    stage = 'bp-crit'
  }
  else if (between(sysVal, BP_SYS_STAGE_TWO) || between(diaVal, BP_DIA_STAGE_TWO)) {
    stage = 'bp-stage-2'
  }
  else if (between(sysVal, BP_SYS_STAGE_ONE) || between(diaVal, BP_DIA_STAGE_ONE)) {
    stage = 'bp-stage-1'
  }
  else if (between(sysVal, BP_SYS_ELEVATED) && between(diaVal, BP_DIA_NORMAL)) {
    stage = 'bp-elevated'
  }
  else if (between(sysVal, BP_SYS_NORMAL) && between(diaVal, BP_DIA_NORMAL)) {
    stage = 'bp-normal'
  }
  return stage;
}

const between = (x, [min, max]) => {
  if (x >= min && x < max) {
    return true;
  }
  return false;
}
