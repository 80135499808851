import axios from 'axios';

export const api = axios.create({
  baseURL: "https://gql.sharecare.dev-new.ihealthnext.com:4200",
  headers: {
    'Origin': 'https://gql.sharecare.dev-new.ihealthnext.com:4200'
  }
})

// export const login = ({username, password}) => {
//     return axios.post({
//       url: 'https://gql.sharecare.dev-new.ihealthnext.com:4200/graphiql',
//       method: 'POST',
//       data: {
//         query: `
//           mutation loginWithUsernameOrEmail($username: String!, $password: String!) {
//             loginWithUsernameOrEmail(usernameOrEmail: $username, password: $password) {
//               id
//               active
//               sessionToken
//               username
//               role {
//                 refId
//                 name
//                 roleType
//                 startFrom
//                 category
//                 active
//               }
//               allRoles {
//                 refId 
//                 name
//                 roleType
//                 startFrom
//                 category
//                 active
//               }
//               lastLogIn {
//                 id
//               }
//             }
//           }
//         `,
//         variables: {
//           username,
//           password
//         }
//       },
//       headers: {
//         'Origin': 'https://gql.sharecare.dev-new.ihealthnext.com:4200/graphiql'
//       }
//     }
//   )
// }

export const login = ({username, password}) => {
  return axios.post(
    'https://gql.sharecare.dev-new.ihealthnext.com:4200/users/login',
    {
      username,
      password 
    },
    {
      headers: {
      'Origin': 'https://gql.sharecare.dev-new.ihealthnext.com:4200',
      'x-api-key': '35dbd20e-16f4-4b46-ac57-74927283af7a'}
    }
  )
}

const apis = {
  login
}

export default apis;