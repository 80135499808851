import React from 'react';
import _ from 'lodash';
import { useStore } from '../StoreProvider';

import {
  AllergiesVisualizer,
  CarePlansVisualizer,
  ConditionsVisualizer,
  EncountersVisualizer,
  ImmunizationsVisualizer,
  MedicationsVisualizer,
  ObservationsVisualizer,
  PatientVisualizer,
  ProceduresVisualizer,
  ReportsVisualizer
} from './fhir-visualizer';
import Measurement from '../Measurement';
import { finished } from 'stream';
import Tab from '../Tab/Tab';

const getResourceByType = ( patientRecord, resourceType ) => {
  if (!patientRecord) {
    return [];
  }
  return patientRecord.filter(resource => resource.resourceType === resourceType);
};

const showTab = (rows) => {
  return rows && rows.length > 0;
}

const SECTION_MAP = [
  { name: 'Condition', resourceName: 'Condition', path: 'resources', Visualizer: ConditionsVisualizer },
  { name: 'Observation', resourceName: 'Observation', path: 'store.observations', Visualizer: ObservationsVisualizer },
  { name: 'Diagnostic', resourceName: 'DiagnosticReport', path: 'resources', Visualizer: ReportsVisualizer },
  { name: 'Medication', resourceName: 'MedicationRequest', path: 'resources', Visualizer: MedicationsVisualizer },
  { name: 'Allergy', resourceName: 'AllergyIntolerance', path: 'resources', Visualizer: AllergiesVisualizer },
  { name: 'Care Plan', resourceName: 'CarePlan', path: 'resources', Visualizer: CarePlansVisualizer },
  { name: 'Procedure', resourceName: 'Procedure', path: 'resources', Visualizer: ProceduresVisualizer },
  { name: 'Encounter', resourceName: 'Encounter', path: 'resources', Visualizer: EncountersVisualizer },
  { name: 'Immunization', resourceName: 'Immunization', path: 'resources', Visualizer: ImmunizationsVisualizer },
]

const PatientRecord = ({ resources, loading, client }) => {
  const { store, dispatch } = useStore();

  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '100%', marginTop: 10}}>
        {/* <PatientVisualizer dispatch={dispatch} client={client} patient={store.patient} observations={getResourceByType(resources, 'Observation')}/> */}
        <Measurement store={store} client={client} loading={loading} dispatch={dispatch} encounter={getResourceByType(store.records, 'Encounter').find(e => e.status === 'in-progress' || e.status === 'planned' || e.status === 'finished')}/>
        <div style={{width: '100%', marginTop: 10}}>
          <Tab>
            {_.map(SECTION_MAP, v => {
              const { Visualizer, path, resourceName, name } = v;
              const resource = _.get({ store, resources }, path);
              const rows = getResourceByType(resource, resourceName);
              if (!showTab(rows)) return null;
              return (
                <Tab.TabPane key={name} title={name}>
                  <Visualizer rows={rows} />
                </Tab.TabPane>
              );
            }).filter(v => v !== null)}
          </Tab>
        </div>
      </div>
    </div>
  );
};

export default PatientRecord;
