export const CERNER_SCOPES = "launch launch/encounter online_access openid profile user/Account.read user/AllergyIntolerance.read user/Appointment.read user/CarePlan.read user/CareTeam.read user/Condition.read user/Coverage.read user/Device.read patient/Device.read user/DocumentReference.read user/Encounter.read user/Immunization.read user/MedicationRequest.read user/NutritionOrder.read user/Observation.read user/Organization.read user/Patient.read user/Person.read user/Practitioner.read user/Procedure.read user/ServiceRequest.read"
export const EPIC_SCOPES = "launch launch/encounter online_access openid profile user/Account.read user/AllergyIntolerance.read user/Appointment.read user/CarePlan.read user/CareTeam.read user/Condition.read user/Coverage.read user/Device.read patient/Device.read user/DocumentReference.read user/Encounter.read user/Immunization.read user/MedicationRequest.read user/NutritionOrder.read user/Observation.read user/Organization.read user/Patient.read user/Patient.create user/Patient.search user/Person.read user/Practitioner.read user/Procedure.read user/ServiceRequest.read"
export const SUPPORTED_RESOURCES = ["Patient", "Condition", "Observation", "Organization", "Encounter", "MedicationRequest", "MedicationStatement", "Medication", "Coverage"]
export const BG_CRIT_HI = 10;
export const BG_CRIT_LO = [0, 3.333];
export const BG_LO = [3.333, 4.444];
export const BG_NORMAL=[4.444, 7.222];
export const BG_HI=[7.222, 10]
export const BP_SYS_LO = [0, 89];
export const BP_SYS_NORMAL=[90, 120];
export const BP_SYS_ELEVATED=[120, 130];
export const BP_SYS_STAGE_ONE=[130, 140];
export const BP_SYS_STAGE_TWO=[140, 180];
export const BP_SYS_CRIT_HI=[180, 1000];
export const BP_DIA_NORMAL=[0, 80];
export const BP_DIA_STAGE_ONE=[80, 90];
export const BP_DIA_STAGE_TWO=[90, 120];
export const BP_DIA_CRIT_HI=[120, 1000];
export const BG_CODE_TEXT = 'Glucose';
export const BG_FASTING_CODE_TEXT = 'Glucose, Fasting';
export const BP_CODE_TEXT = 'Blood pressure panel with all children optional';
export const GLUCOSE_CODE='2345-7';
export const FASTING_GLUCOSE_CODE='1558-6';
export const BLOOD_PRESSURE_CODE='85354-9';
export const REFER_PATIENT = 'refer';
export const ENROLLED = 'arrived';
export const DISCHARGED = 'cancelled';
export const INIT = 'planned';
export const REFERRED = 'referred';
export const SHARECARE_ACCOUNT_ID = 'SHARECARE ACCOUNT ID';