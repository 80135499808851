import React from 'react';
import { parseBgTableData, getBgStage } from './helper';
import overnight from '../../logos/overnight.svg';
import bedtime from '../../logos/bedtime.svg';
import _ from 'lodash';
import '../../App.scss';
import { Table } from 'antd';
import MeasurementTableHeader from './MeasurementTableHeader';
import moment from 'moment';
import { BG_CODE_TEXT, BG_FASTING_CODE_TEXT } from '../../utils/constants';

const BGTable = ({ store, visible, setVisible, modalData, setModalData, bgDates, setBgDates, bgDateDiff, setBgDateDiff }) => {
  const measurements = _.get(store, 'bgMeasurements', []);
  const bgMeasurements = measurements.filter((m) => _.get(m, 'resource.code.text') === (BG_CODE_TEXT) || _.get(m, 'resource.code.text') === (BG_FASTING_CODE_TEXT));

  const renderValue = (data = []) => {
    const displayed= _.get(data, '[0].resource');
    const val = _.get(displayed, 'valueQuantity.value');
    const stage = getBgStage(displayed);

    return <div className={`bg-table-cell ${stage}`}>
      <div className='bg-count'>{data && data.length >= 2 ? data.length : <p/> }</div>
      <div className='bg-reading'>{val ? Math.round(val * 18) : <p/>}</div>
    </div>
  }

  const renderOvernightIcon = () => {
    return <img src={overnight} style={{width: '1.5rem'}} alt='overnight'/>
  }

  const renderBedtimeIcon = () => {
    return <img src={bedtime} style={{width: '1.5rem'}} alt='bedtime'/>
  }

  const renderFooter = () => {
    return <span>Measurements are in <b>mg/dL</b></span>
  }

  const handleCellClick = (data) => {
    if (data.length > 0) {
      setVisible(true);
      setModalData(data);
    }
  }

  const groupData = (tableData) => {
    const fromDate = moment(bgDates[0]);
    const toDate = moment(bgDates[1]);

    while(fromDate.isSameOrBefore(toDate,'day')){
        if (!tableData[fromDate.format('YYYY-MM-DD')]) {
          tableData[fromDate.format('YYYY-MM-DD')] = {date: fromDate.format('YYYY-MM-DD')};
        }
        fromDate.add(1,'day');
    }

    return Object.values(tableData).sort((a, b) => a.date.localeCompare(b.date));
  }

  const columns = [
    {
      title: <MeasurementTableHeader bgDates={bgDates} setBgDates={setBgDates} bgDateDiff={bgDateDiff} setBgDateDiff={setBgDateDiff}/>,
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          width: '12%',
          align:'center',
          ellipsis: true,
          sorter: (a, b) =>  a.date.localeCompare(b.date),
          sortDirections: ['descend', 'ascend']
        },
        {
          title: renderOvernightIcon,
          dataIndex: 'overnight',
          width: '8%',
          align:'center',
          render: renderValue,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'overnight', []))
            }
          }
        },
        {
          title: 'Breakfast',
          width: '16%',
          align:'center',
          children: [
            {
              title: 'B',
              dataIndex: ['breakfast','before'],
              width: '8%',
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'breakfast.before', []))
                }
              }
            },
            {
              title: 'A',
              dataIndex: ['breakfast','after'],
              width: '8%',
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'breakfast.after', []))
                }
              }
            }
          ]
        },
        {
          title: 'Lunch',
          width: '16%',
          align:'center',
          children: [
            {
              title: 'B',
              dataIndex: ['lunch', 'before'],
              key: 'lunch',
              width: "8%",
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'lunch.before', []))
                }
              }
            },
            {
              title: 'A',
              dataIndex: ['lunch', 'after'],
              width: '8%',
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'lunch.after', []))
                }
              }
            }
          ]
        },
        {
          title: 'Dinner',
          width: '16%',
          align:'center',
          children: [
            {
              title: 'B',
              dataIndex: ['dinner', 'before'],
              key: 'dinner',
              width: '8%',
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'dinner.before', []))
                }
              }
            },
            {
              title: 'A',
              dataIndex: ['dinner', 'after'],
              width: '8%',
              align:'center',
              render: renderValue,
              ellipsis: true,
              onCell: (data) => {
                return {
                  onClick: () => handleCellClick(_.get(data, 'dinner.after', []))
                }
              }
            }
          ]
        },
        {
          title: renderBedtimeIcon,
          dataIndex: 'bedtime',
          width: '8%',
          align:'center',
          render: renderValue,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'bedtime', []))
            }
          }
        }
      ]
    }
  ]

  return (
    <div>
      <Table
        dataSource={groupData(parseBgTableData(bgMeasurements))}
        columns={columns}
        rowKey='date'
        bordered
        className='measurement-table'
        scroll={{scrollToFirstRowOnChange: true, y: 220}}
        pagination={false}
        footer={renderFooter}
        size='small'
      />
    </div>
  )
}

export default BGTable;
