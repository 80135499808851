import React from 'react';
import _ from 'lodash';
import '../../App.scss'
import { Table } from 'antd';
import { parseBpSummaryData } from './helper';

const BPSummary = ({ store }) => {
  const measurements = _.get(store, 'bpMeasurements', []);
  const bpMeasurements = measurements.filter((m) => _.get(m, 'resource.code.text') === ('Blood pressure panel with all children optional'));
  
  const dataSource = parseBpSummaryData(bpMeasurements);

  const columns = [
    {
      title: 'Blood Pressure Summary',
      align:'center',
      children: [
        {
            title:'Type',
            key:'type',
            align:'center',
            dataIndex: 'type',
            width: '25%',
            ellipsis: true
        },
        {
            title:'Ct.',
            key:'count',
            align:'center',
            dataIndex:'count',
            width: '25%',
            ellipsis: true
        },
        {
            title:'Average\n(mmHg)',
            key:'average',
            align:'center',
            dataIndex:'average',
            width: '25%',
            ellipsis: true
        },
        {
            title:'Range\n(mmHg)',
            key:'range',
            align:'center',
            dataIndex:'range',
            width: '25%',
            ellipsis: true
        }
      ]
    }
  ];

  return <Table 
            dataSource={dataSource}
            columns={columns}
            rowKey='type'
            tableLayout='fixed'
            bordered
            pagination={false}
            style={{justifyContent: 'space-between'}}
            rowClassName='bp-cell'
            className='summary-table'
            size='small'
        />
}

export default BPSummary;