import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal, Button } from 'antd';
import { useStore } from '../StoreProvider';
import { api } from '../../api';
import classes from './DupPatientListModal.module.scss';
import moment from 'moment';
import { REFER_PATIENT } from '../../utils/constants';

const DupPatientListModal = ({patients, dup, setDup, referPatient, setReferSuccess, setModalContent, setVisible, setButtonLeft, setButtonRight, setFooter}) => {
  const { store, dispatch } = useStore();
  const [selectIndex, setSelectIndex] = useState(null);
  const [enrollEncounters, setEnrollEncounters] = useState([]);

  useEffect(() => {
    const encounters = [];
    const promises = [];
    patients.forEach((r, i) => {
      const id = _.get(r, 'resource.id');
      const promise = api.get(`Encounter?patient=${id}`);
      promises.push(promise);
    })
    Promise.all(promises).then((values) => {
      for (const val of values) {
        const encounter = _.get(val, 'data.entry[0]');
        encounters.push(encounter)
      }
      setEnrollEncounters(encounters);
    })
  }, [patients])

  const renderEnrolledInfo = (enc, index) => {
    if (enc[index]) {
      const enrollStatus = _.get(enc[index], 'resource.extension[0].valueCode', "");
      const date = moment(_.get(enc[index], 'resource.entry[0].meta.lastUpdated')).format('MM/DD/YYYY');
    
      return <div className={classes['patient-dup-child__enroll-status']}>
        <div>{enrollStatus.substr(0,1) + enrollStatus.substr(1).toLowerCase()}</div>
        <div>{date}</div>
      </div>
    } else {
      return <div className={classes['patient-dup-child__enroll-status']}>
        Unenrolled
      </div>
    }
  }

  const renderPatientsList = () =>  {
    return patients.map((r, i) => {
      const name = _.get(r, 'resource.name[0].text') || _.get(r, 'resource.name[0].given[0]') + ' ' +  _.get(r, 'resource.name[0].family');
      const identifiers = _.get(r, 'resource.identifier', []);
      const mrn = _.get(_.find(identifiers, (i) => _.get(i, 'type.text') === 'MRN'), 'value') || "";
    
      return (
          <div className={classes['patient-dup-child']} key={i}>
            <div className={classes['patient-dup-child__left']}>
              <div className={classes['patient-dup-child__name']}>{name}</div>
              <hr></hr>
              <div style={{display: 'flex'}}>
                <div className={classes['patient-dup-child__info']}>MRN: {mrn}</div>
                <div className={classes['patient-dup-child__info']}>DOB: {moment(r.resource.birthDate).format('MM/DD/YYYY')}</div>
              </div>
            </div>
            <div className={classes['patient-dup-child__right']}>
              <Button 
                key={i}
                onClick={() => setSelectIndex(i)}
                type={i === selectIndex ? 'primary' : 'default'}
                className={classes['patient-dup-child__select']}
              >
              Select
              </Button>
              {renderEnrolledInfo(enrollEncounters, i)}
            </div>
          </div>
        )
      }
    )
  }

  const handleDone = () => {
    if (!Number.isInteger(selectIndex)) {
      return;
    }

    const epicId = _.get(store, 'patient.id');
    const patient = _.get(patients[selectIndex], 'resource');
    const patientId = _.get(patient, 'id');
    const editedPatient = Object.assign({}, patient);
    const identifier = (_.get(editedPatient, 'identifier', []));
    identifier.push({
      "use": "usual",
      "type": {
          "text": "EPIC FHIR STU3"
      },
      "value": epicId
    });
    editedPatient.identifier = identifier;
    api.put(`Patient/${patientId}`, editedPatient).then((res) => {
      if (res.status === 200) {
        const enrolledPatient = res.data;
        dispatch({type: 'updateEnrolledPatient', enrolledPatient});
        dispatch({type: 'updateEnrollStatus', enrollStatus: REFER_PATIENT});
        setDup(false)
        setReferSuccess(true);
      }
    });
  }

  const handleButtonBack = () => {
    setVisible(false);
    setDup(true);
  }

  const handleButtonLater = () => {
    setVisible(false)
  }

  const handleCancelClick = () => {
    setDup(false);
    setModalContent(<div><div className={classes['modal-header']}>Are you sure you want to close the window?</div>
                    <div className={classes['modal-sub-message']}>You can only see patient's measurement data after selecting the patient.</div></div>)
    setButtonLeft(<Button className={classes['modal-button-long']} type='primary' onClick={() => handleButtonBack()}>Back to Select</Button>)
    setButtonRight(<Button className={classes['modal-button']} type='default' onClick={() => handleButtonLater( )}>Close</Button>)
    setFooter("")
    setVisible(true)
  }

  const handleFooterClick = () => {
    setModalContent(<div className={classes['modal-header']}>Sorry, we couldn't find other patients that match your search. Would you like to refer this patient?</div>)
    setButtonLeft(<Button className={classes['modal-button']} type='primary' onClick={() => referPatient(store.patient)}>Refer</Button>)
    setButtonRight(<Button className={classes['modal-button']} type='default' onClick={() => handleButtonBack()}>Go Back</Button>)
    setFooter(<div onClick={() => setVisible(false)}>Refer it later</div>)
    setDup(false)
    setVisible(true)
  }

  return <Modal
    visible={dup}
    maskClosable={false}
    footer={null}
    onCancel={() => handleCancelClick()}
    width={480}
  >
    <div className={classes['dup-list-header']}>We found multiple results that matched your search, please select the correct patient.</div>
    {renderPatientsList()}
    <Button className={classes['done-button']} type='primary' disabled={Number.isInteger(selectIndex) ? false : true} onClick={() => handleDone()}>Done</Button>
    <div className={classes['footer-link']} onClick={() => handleFooterClick()}>Can't find the right patient?</div>
  </Modal>
}

export default DupPatientListModal;