import React from 'react';
import { Modal, Button, } from 'antd';
import classes from './TwoButtonMessageModal.module.scss';
import { useStore } from '../StoreProvider';
import _ from 'lodash';

const TwoButtonMessageModal = ({visible, modalContent, buttonLeft, buttonRight, footer}) => {
  const store = useStore();
  const onClickLeft = _.get(buttonLeft, 'onClick');
  const onClickRight = _.get(buttonRight, 'onClick');

  return <Modal
    visible={visible}
    footer={null}
    closable={false}
  >
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div className={classes['modal-message']}>{modalContent}</div>
      <div className={classes['modal-button-container']}>
        {buttonLeft}
        {buttonRight}
      </div>
      <div className={classes['modal-message-footer']}>{footer}</div>
    </div>
  </Modal>
}

export default TwoButtonMessageModal;