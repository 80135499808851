import React from 'react';
import _ from 'lodash';
import '../../App.scss'
import { Table } from 'antd';
import { parseBgSummaryData } from './helper';

const BGSummary = ({ store }) => {
  const measurements = _.get(store, 'bgMeasurements', []);
  const bgMeasurements = measurements.filter((m) => _.get(m, 'resource.code.text') === ('Glucose') || _.get(m, 'resource.code.text') === ('Glucose, Fasting'));

  const dataSource = parseBgSummaryData(bgMeasurements);

  const columns = [
      {
          title: 'Blood Glucose Summary',
          children: [
            {
                title:'Type',
                key:'type',
                align:'center',
                dataIndex: 'type',
                width: '25%',
                ellipsis: true
            },
            {
                title:'Ct.',
                key:'count',
                align:'center',
                dataIndex:'count',
                width: '25%',
                ellipsis: true
            },
            {
                title:'Average\n(mg/dL)',
                key:'average',
                align:'center',
                dataIndex:'average',
                width: '25%',
                ellipsis: true
            },
            {
                title:'Range\n(mg/dL)',
                key:'range',
                align:'center',
                dataIndex:'range',
                width: '25%',
                ellipsis: true
            }
          ]
      }
  ];

  return <Table 
                  dataSource={dataSource}
                  columns={columns}
                  rowKey='type'
                  bordered
                  pagination={false}
                  className='summary-table'
                  size='small'
              />
}

export default BGSummary;
