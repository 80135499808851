import React from 'react';
import moment, { utc } from 'moment-timezone';
import _ from 'lodash';
import '../../App.scss';
import { getBpStage, parseBpTableData } from './helper';
import { Table } from 'antd';
import MeasurementTableHeader from './MeasurementTableHeader';
import { BP_CODE_TEXT } from '../../utils/constants';


const BPTable = ({ store, visible, setVisible, modalData, setModalData, bpDates, setBpDates, bpDateDiff, setBpDateDiff }) => {
  const measurements = _.get(store, 'bpMeasurements', []);
  const bpMeasurements = measurements.filter((m) => _.get(m, 'resource.code.text') === (BP_CODE_TEXT));
  
  const renderVal = (data = []) => {
    const displayed = _.get(data, '[0]');
    const bpm = _.get(displayed, 'resource.component[0].valueQuantity.value', "");
    const sysVal = _.get(displayed, 'resource.component[1].valueQuantity.value', "");
    const diaVal = _.get(displayed, 'resource.component[2].valueQuantity.value', "");
    const stage = getBpStage(displayed);

    return <div className={`bp-table-cell ${stage}`}>
      <div className='bp-count'>{data.length >= 2 ? data.length : "" }</div>
      <div className='bp-reading'>{sysVal + '/' + diaVal + ' ' + bpm + 'bpm'}</div>
    </div>
  }

  const renderFooter = () => {
    return <span>Measurements are in <b>mmHg</b></span>
  }

  const handleCellClick = (data) => {
    if (data.length > 0) {
      setVisible(true);
      setModalData(data);
    }
  }

  const groupData = (tableData) => {
    const fromDate = moment(bpDates[0]);
    const toDate = moment(bpDates[1]);

    while(fromDate.isSameOrBefore(toDate,'day')){
        if (!tableData[fromDate.format('YYYY-MM-DD')]) {
          tableData[fromDate.format('YYYY-MM-DD')] = {date: fromDate.format('YYYY-MM-DD')};
        }
        fromDate.add(1,'day');
    }

    return Object.values(tableData).sort((a, b) => a.date.localeCompare(b.date));
  }

  const columns = [
    {
      title: <MeasurementTableHeader bpDates={bpDates} setBpDates={setBpDates} bpDateDiff={bpDateDiff} setBpDateDiff={setBpDateDiff}/>,
      align: 'center',
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          align: 'center',
          width: '20%',
          ellipsis: true,
          sorter: (a, b) =>  a.date.localeCompare(b.date),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Overnight',
          dataIndex: 'overnight',
          align: 'center',
          width: '20%',
          render: renderVal,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'overnight', []))
            }
          }
        },
        {
          title: 'Morning',
          dataIndex: 'morning',
          align: 'center',
          width: '20%',
          render: renderVal,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'morning', []))
            }
          }
        },
        {
          title: 'Afternoon',
          dataIndex: 'afternoon',
          align: 'center',
          width: '20%',
          render: renderVal,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'afternoon', []))
            }
          }
        },
        {
          title: 'Evening',
          dataIndex: 'evening',
          align: 'center',
          width: '20%',
          render: renderVal,
          ellipsis: true,
          onCell: (data) => {
            return {
              onClick: () => handleCellClick(_.get(data, 'evening', []))
            }
          }
        },
      ]
    }
  ]

  
  return (
    <Table
      dataSource={groupData(parseBpTableData(bpMeasurements))}
      columns={columns}
      rowKey='date'
      className='measurement-table'
      bordered
      scroll={{scrollToFirstRowOnChange: true, y: 300}}
      pagination={false}
      footer={renderFooter}
      size='small'
    />
  )
}

export default BPTable;