import React from 'react';
import { Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import classes from './ReferPatientSuccessModal.module.scss';

const ReferPatientSuccesModal = ({referSuccess, setReferSuccess}) => {
  return <Modal
    visible={referSuccess}
    footer={null}
    maskClosable={true}
    closable={false}
    onCancel={() => setReferSuccess(false)}
  >
    <div className={classes['success-modal-container']}>
      <CheckCircleFilled style={{fontSize: '60px', color: '#3177C9', margin: '20px'}}></CheckCircleFilled>
      <div className={classes['success-modal-header']}>You have referred the patient successfully!</div>
      <div className={classes['success-modal-message']}>Once the patient enrolls in the program, visit information will be updated in the system automatically.</div>
    </div>
  </Modal>
}

export default ReferPatientSuccesModal;