import { Button,Radio } from 'antd';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getBgObservations, getBpObservations } from '../../api';
import { useStore } from '../StoreProvider';

const MeasurementTableHeader = ({ bgDates, setBgDates, bgDateDiff, setBgDateDiff, bpDates, setBpDates, bpDateDiff, setBpDateDiff}) => {
  const { store, dispatch } = useStore();
  const { enrolledPatient } = store;
  const dateFormat = 'YYYY-MM-DD';

  const handleRadioClick = async (e) => {
    const val = e.target.value;
    let toDate = moment().format(dateFormat);
    let fromDate;
    let newDateDiff;
    
    if (val === 'a') {
      fromDate = moment().subtract(1, 'weeks').format(dateFormat);
      newDateDiff = [1, 'weeks'];
    }
    else if (val === 'b') {
      fromDate = moment().subtract(2, 'weeks').format(dateFormat);
      newDateDiff = [2, 'weeks'];
    }
    else if (val === 'c') {
      fromDate = moment().subtract(1, 'months').format(dateFormat);
      newDateDiff = [1, 'months'];
    }
    if (bgDates) {
      setBgDates([fromDate, toDate]);
      setBgDateDiff(newDateDiff);
      const bg = await getBgObservations(enrolledPatient.id, fromDate, toDate);
      dispatch({ type: 'updateBgMeasurements', bgMeasurements: bg.data.entry || [] });
    } else if (bpDates) {
      setBpDates([fromDate, toDate]);
      setBpDateDiff(newDateDiff);
      const bp = await getBpObservations(enrolledPatient.id, fromDate, toDate);
      dispatch({ type: 'updateBpMeasurements', bpMeasurements: bp.data.entry || [] });
    }
  }

  const handleDateSelect = async (e) => {
    const val = e.currentTarget.value;
    let fromDate;
    let toDate;
    if (bgDates) {
      fromDate = moment(bgDates[0]);
      toDate = moment(bgDates[1]);
      if (val === 'left') {
        fromDate = moment(fromDate).subtract(bgDateDiff[0], bgDateDiff[1]).format(dateFormat);
        toDate = moment(toDate).subtract(bgDateDiff[0], bgDateDiff[1]).format(dateFormat);
      }
      else if (val === 'right') {
        fromDate = moment(fromDate).add(bgDateDiff[0], bgDateDiff[1]).format(dateFormat);
        toDate = moment(toDate).add(bgDateDiff[0], bgDateDiff[1]).format(dateFormat);
      }
      console.log([fromDate, toDate])
      setBgDates([fromDate,toDate]);
      const bg = await getBgObservations(enrolledPatient.id, fromDate, toDate);
      dispatch({ type: 'updateBgMeasurements', bgMeasurements: bg.data.entry || [] });
    }
    else if (bpDates) {
      fromDate = bpDates[0];
      toDate = bpDates[1];
    
      if (val === 'left') {
        fromDate = moment(fromDate).subtract(bpDateDiff[0], bpDateDiff[1]).format(dateFormat);
        toDate = moment(toDate).subtract(bpDateDiff[0], bpDateDiff[1]).format(dateFormat);
      }
      else if (val === 'right') {
        fromDate = moment(fromDate).add(bpDateDiff[0], bpDateDiff[1]).format(dateFormat);
        toDate = moment(toDate).add(bpDateDiff[0], bpDateDiff[1]).format(dateFormat);
      }
      setBpDates([fromDate,toDate]);
      const bp = await getBpObservations(enrolledPatient.id, fromDate, toDate);
      dispatch({ type: 'updateBpMeasurements', bpMeasurements: bp.data.entry || [] });
    }
  }

  const renderDateSelect = () => {
    let fromDate = bgDates ? moment(bgDates[0]).format('MM/DD/YYYY') : bpDates ? moment(bpDates[0]).format('MM/DD/YYYY') : "";
    let toDate = bgDates ? moment(bgDates[1]).format('MM/DD/YYYY') : bpDates ? moment(bpDates[1]).format('MM/DD/YYYY') : "";

    return <div>
      <Button
        icon={<LeftOutlined/>}
        size='small'
        value='left'
        onClick={handleDateSelect}
      ></Button>
      {fromDate} - {toDate}
      <Button
        icon={<RightOutlined/>}
        size='small'
        value='right'
        onClick={handleDateSelect}
      />
    </div>
  }

  const renderDateRadio = () => {
    return (
      <Radio.Group onChange={handleRadioClick} defaultValue='a'>
        <Radio.Button value='a'>1 Week</Radio.Button>
        <Radio.Button value='b'>2 Week</Radio.Button>
        <Radio.Button value='c'>Month</Radio.Button>
      </Radio.Group>
    )
  }

    return <div 
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {bgDates ? <h6>Blood Glucose</h6> : bpDates ? <h6>Blood Pressure</h6> : ""}
            {renderDateSelect()}
            {renderDateRadio()}
          </div>
}

export default MeasurementTableHeader;
