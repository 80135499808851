import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { ObjectId } from 'bson';
import '../../App.scss'
import { Button, Form, Input, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../logos/smartonfhir-logo.png';
import { login } from '../../gql';
import { api, setBearerToken } from '../../api';
import { createPractitioner } from '../../api';
import { useStore } from "../StoreProvider";
import { useFHIRClient } from '../FHIRClient';
import { errors } from '../../utils/errors';
import { CheckCircleFilled } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

const getRoleId = (refId) => {
  const decrypted = atob(refId);
  const roleId = decrypted.split(':')[1];

  return roleId;
}

const LoginModal = ({visible, setVisible, error, setError}) => {
  const { store, dispatch }  = useStore();
  const { ehrPractitioner } = store;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [touched, setTouched] = useState(false);
  const [success, setSuccess] = useState(false);
  const client = useFHIRClient();

  const linkAndLoginPractitioner = (loginRes) => {
    const ucToken = _.get(loginRes, 'data.token');
    const epicToken = _.get(client, 'state.tokenResponse.id_token');

    const linkBody = {
      applicationName: "ucfhir",
      method: "token",
      parameters: {
        epicAuthenticationMethod: "token",
        epicToken: epicToken,
        ucId: {
          applicationName: "unifiedcare",
          method: "token",
          parameters: {
            ucToken: ucToken
          }
        }
      },
    }
    
    return linkPractitioner(linkBody).then((linkRes) => {
      if (_.get(linkRes, 'status') === 201) {
        return authnLogin()
      }
    })
  }

  const linkPractitioner = (linkBody) => {
    return axios.post(process.env.REACT_APP_SOTER_URL + '/app/account', linkBody);
  }

  const authnLogin = () => {
    const epicIdToken = _.get(store, 'client.state.tokenResponse.id_token');
  
    const body = {
      applicationName: "ucfhir",
      method: "token",
      parameters: {
        epicToken: epicIdToken
      }
    }
    return axios.post(process.env.REACT_APP_SOTER_URL + '/app/account/login', body).then((authRes) => {
      const bearerToken = _.get(authRes, 'data');
      const decoded = jwt_decode(bearerToken);
      const ucId = _.get(decoded, 'sub');
 
      return new Promise((resolve, reject) => {
        if (bearerToken && ucId) {
          setBearerToken(bearerToken);
          api.get(`Practitioner/${ucId}`).then((ucPractitioner) => {
              dispatch({type: 'updateIhPractitioner', ucPractitioner});
              dispatch({type: 'updateLoggedIn', loggedIn: true});
              setSuccess(true);
              return resolve(true);
            }).catch((e) => {
              setError('We could not find a your account, please contact us to help resolve this issue.');
              setLoading(false);
              return reject(false);
            })
        } else {
          setError('Wrong username or password, please try again');
          return reject('false');
        }
      })
    })
  }

  const onFinish = async ({username, password}) => {
    setLoading(true);
    login({username, password}).then((loginRes) => {
      if (loginRes.status === 200) {
        linkAndLoginPractitioner(loginRes)
      }
    }).catch((e) => {
      console.log(e)
      setError(errors.invalid_credentials);
      setLoading(false);
    })
  }

  const renderButton = () => {
    return loading ? <Spin/> : <Button type='primary' htmlType='submit' disabled={!touched} className='loginButton'>Link account</Button>;
  }

  const renderError = () => {
    return error ? <div className='loginError'>{error}</div> : null;
  }

  const renderContent = () => {
    return success ? <div className='loginPanel'>
      <CheckCircleFilled className='loginSuccessIcon'></CheckCircleFilled>
      <div className='loginSuccessBold'>Success!</div>
      <div className='loginSuccessNormal'>Your Epic Account is now linked with Unified Care</div>
      <Button className='loginSuccessButton' type='primary' onClick={() => setVisible(false)}>OK</Button>
    </div>: <div className='loginPanel'>
        {/* <img className='smartonfhir-logo' src={logo} alt='logo'/> */}
        <div className='loginTextNormal'>Link Epic account with Unified Care</div>
        <div className='loginTextBold'>Enter your Unified Care account Information</div>
        <Form form={form} layout='vertical' size='large' onFinish={onFinish} onFieldsChange={(changedFields, allFields) => onFieldsChange(allFields)} style={{width: '80%', textAlign: 'center'}}>
          <div className='loginInputLabel'>Username</div>
          <Form.Item
            noStyle={true}
            name='username'
            rules={[{required: 'true', message: 'Please input your username'}]}
          >
            <Input placeholder="Login ID or Email"></Input>
          </Form.Item>
          <div className='loginInputLabel'>Password</div>
          <Form.Item
            noStyle={true}
            name='password'
            rules={[{required: 'true', message: 'Please input your password'}]}
            >
            <Input placeholder="" type='password'></Input>
          </Form.Item>
          {/* <div className='loginError'></div> */}
          {renderError()}
          <Form.Item>
            {renderButton()}
          </Form.Item>
        </Form>
      </div>
  }

  const onFieldsChange = (allFields) => {
    const t = allFields.filter((f) => f.touched);
    const e = allFields.filter((f) => f.errors.length > 0);
   
    if (t.length == 2 && e.length == 0) {
      setTouched(true);
    } else {
      setTouched(false)
    }
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      width='400px'
      bodyStyle={{
        height: '500px'
      }}
      closable={false}
    >
      <div className='loginContainer'>
        {renderContent()}
      </div>
    </Modal>
  )
}

export default LoginModal;
